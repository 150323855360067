import React from 'react'

import SEO from '../components/seo'

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <article className="not-found" style={{
      background: 'url("404.png") center / cover',
      width: '100vw',
      height: '100vh',
      position: 'absolute',
      top: 0,
      left: 0
    }}>

      <a href="/" style={{
        width: '100%',
        height: '100%',
        display: 'block',
        color: '#f00'
      }}>
        <h1 style={{
          textTransform: 'uppercase',
          textAlign: 'center',
          position: 'fixed',
          bottom: '50px',
          fontSize: '5rem',
          width: '100%',
          textIndent: 0
        }}>Unauthorized access</h1></a>
    </article>
  </>
)

export default NotFoundPage
